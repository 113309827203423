@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
}

.main_screen {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 0;
  overflow-y: auto;
  overflow-x: auto;
}

.header_bar {
  position: fixed;
  top: 0px;
  left: 0px;
  display: block;
  width: 100%;
  /*height: 92px;*/
  height: 100px;
  background-color: #CCE0D4;
  z-index: 1;
}

.status_bar {
  display: grid;
  grid-template-columns: auto 300px;
  gap: 5px;
  background-color: #CCE0D4;
  justify-items: center;
  align-items: center;
  column-gap: 20px;
  height: 75px;
  width: 100%;
  font: Inter;
}


.main_menu {
  width: 100%;
  height: 17px;
  padding-left: 100px;
  background-color: #CCE0D4;
  font-size: 14px;
  font-weight: 400;
  font: Inter;
}

.menu_item {
  margin-left: 25px;
  margin-right: 25px;
  color: #373737;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  font: Inter;
}

.active {
  color: #E47E1A;
}

.burger_main_menu {
  background: none;
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
  display: none;
}

.mobile_menu {
  position: relative;
  top: 0px;
  left: 0px;
  width: fit-content;
  background: white;
  /*background: #1E7040;*/
  color: black;
  /*list-style: none;*/
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  gap: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

.screen {
  display: flex;
  padding-top: 100px;
  flex-direction: column;
  background-color: #CCE0D4;
  width: 100%;
  height: 100%;
  font: Inter;
}

.screen_content {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items:flex-start;
  background-color: #CCE0D4;
  /*height: 500px;*/
  height: fit-content;
  width: 100%;
  font: Inter;
}

.footer_common {
  width: 100%;
  height: 90px;
  display: block;
  overflow: hidden;
}

.error_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1400;
}

.error_box {
    display: grid;
    grid-template-columns: auto 70px;
    gap: 10px;
    width: 300px;
    min-height: 1px;
}

.error_text {
  background-color: white;
  color: red;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.page404 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.page404 img {
  width: 300px;
  height: 300px;
}

.page404 div {
  display: block;
  text-align: center;
}

.loader_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);*/
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1500;
}

.loader_orange {
  border: 2px solid #f3f3f3; /* Светлый цвет */
  border-top: 2px solid #E47E1A; /* Цвет индикатора */
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;
  display: inline-block;
  margin-right: 5px;
}

.footer_line {
  width: 100%;
  background-color: #1E7040;
  height: 1px;
}

.footer_text {
  width: 100%;
  display: block;
  padding: 10px;
  color: #1E7040;
  background-color: #CCE0D4;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.footer_text a {
  color: #1E7040;
  font-size: 14px;
  font-weight: 400;
}

.append_block {
  margin: 20px;
}

.pagination_block {
  width: 100%;
  min-height: 1px;
  background-color: #CCE0D4;
  margin-top: 5px;
  margin-bottom: 5px;
}

.pagination_bar {
  width: 330px;
  height: 42px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  white-space: nowrap;   /* Запрещает перенос элементов на следующую строку */
  overflow: hidden;      /* Скрывает блоки, не помещающиеся в контейнер */     
}

.paginator_button {
  width: 40px;
  height: 40px;
  background-color: #CCE0D4;
  border: 1px solid #373737;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  object-fit: cover;
  cursor: pointer;
}

.paginator_button.disabled {
  opacity: 0.5; /* Делает кнопку блеклой */
  cursor: not-allowed; /* Указывает, что кнопка неактивна */
  background-color: #CCE0D4;
}

.page_number {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #373737B2;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.not_page_number {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #373737B2;
  margin-left: 10px;
  margin-right: 10px;
}

.page_number_active {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #E47E1A;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: underline;
  font-weight: bold;
}

.burger_menu {
  width: 30px;
  height: 20px;
  /*display: flex;*/
  display: none;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.burger_menu span {
  display: block;
  height: 4px;                  /* Толщина линии */
  width: 100%;                  /* Ширина линии, равна ширине контейнера */
  background-color: #333;       /* Цвет линий */
  border-radius: 2px;           /* Закругление линий (опционально) */
  transition: 0.3s;             /* Анимация при изменении состояния (опционально) */
}

.section_logo {
  justify-self: center;
}

.logo_block {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  background-color: #CCE0D4;
  color: #E47E1A;
  overflow: hidden;
}

.logo_block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section {
  justify-self: left;
}

.section_login {
  justify-self: left;
}

.section_button {
  width: 190px;
  background-color: #1E7040;
  color: white;
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  justify-self: left;
}

.section_button_open {
  width: 190px;
  background-color: #1E7040;
  color: white;
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px 20px 0px 0px;
  border: none;
  cursor: pointer;
  justify-self: left;
}

.section_selector_title {
  display: flex;
  width: 170px;
  color: white;
  background-color: #1E7040;
  margin-top: auto;
  margin-bottom: auto;
}

.section_selector_title_text {
  width: 128px;
  background-color: #1E7040;
  color: white;
  font-weight: 400;
  font-size: 14px;
  margin-left: 5px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 5px;
  text-align: left;
}

.section_selector_title_image {
  width: 16px;
  height: 16px;
  margin-bottom: auto;
  margin-top: auto;
  margin-left: 5px;
  margin-right: 5px;
  object-fit: contain;
}

.section_menu_item {
  display: grid;
  width: 190px;
  grid-template-columns: 20px 25px auto 20px;
  height: 25px;
  margin-top: 7px;
  margin-bottom: 7px;
  background-color: #1E7040;
  color: white;
  border-radius: 20px;
  cursor: pointer;
}

.section_menu_item_image {
  width: 26px;
  height: 26px;
  margin-bottom: auto;
  margin-top: auto;
  margin-left: 5px;
  margin-right: 5px;
  object-fit: contain;
}

.section_menu_item_text {
  background-color: #1E7040;
  color: white;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
  margin-top: 5px;
}

.section_menu_item_text_selected {
  background-color: #1E7040;
  color: white;
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
  margin-top: 5px;
}

.section_selector {
  width: fit-content;
  display: block;
  position: absolute;
  background-color: #1E7040;
  border-radius: 0px 0px 20px 20px;
  z-index: 1;
}

.geography {
  display: flex;
  width: fit-content;
  height: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 7px;
  border-radius: 20px;
  background-color: #1E7040;
  justify-self: right;
  cursor: pointer;
}

.location {
  margin-right: 10px;
}

.location_text {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.language_button {
  width: 88px;
  height: 35px;
  display: inline-flex;
  background-color: #1E7040;
  color: white;
  justify-self: right;
  cursor: pointer;
  border-radius: 20px;
  border: none;
  justify-content: center; /* горизонтальное центрирование */
  align-items: center; /* вертикальное центрирование */
}

.language_button_text {
  color: white;
  margin: 2px;
  font-size: 12px;
  font-weight: 400;
}

.language_button_text_selected {
  color: white;
  margin: 2px;
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
}

.login {
  width: 160px;
  height: 40px;
  margin-left: 5px;
  background-color: cornflowerblue;
}

.login_button {
  width: 180px;
  background-color: #1E7040;
  color: white;
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  justify-self: left;
  overflow: hidden;
}

.login_button_open {
  width: 180px;
  background-color: #1E7040;
  color: white;
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px 20px 0px 0px;
  border: none;
  cursor: pointer;
  justify-self: left;
  overflow: hidden;
}

.login_page {
  display: block;
  background-color: cadetblue;
  color: black;
}

.nic {
  background-color: cornflowerblue;
}

.login_menu {
  width: 180px;
  display: block;
  position: absolute;
  background-color: #1E7040;
  border-radius: 0px 0px 20px 20px;
  z-index: 5000;
}

.language_menu {
  width: 160px;
  min-height: 1px;
  display: block;
  position: relative;
}

.login_menu_item {
  width: 150px;
  color: white;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  z-index: 5000;
}

.login_menu_item_nav {
  color: white;
  text-decoration: none;
}

.user_nic_button {
  display: grid;
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  justify-items: center;
  align-items: self-start;
}

.user_nic {
  display: flex;
  flex-wrap: nowrap;
  margin-left: 5px;
}

.user_nic_avatar {
  width: 16px;
  height: 16px;
}

.user_nic_name {
  margin-left: 5px;
}

.grid-item {
  padding: 10px;
  background-color: #4caf50;
  color: white;
  text-align: center;
}

.burger-icon,
.burger-icon::before,
.burger-icon::after {
  content: "";
  display: block;
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 5px 0;
  transition: 0.3s;
}

/* Анимация для крестика */
.burger-icon.open {
  background-color: transparent;
}

.burger-icon.open::before {
  transform: rotate(45deg) translate(5px, 5px);
}

.burger-icon.open::after {
  transform: rotate(-45deg) translate(5px, -5px);
}

.burger-icon::before,
.burger-icon::after {
  content: "";
  width: 25px;
  height: 3px;
  background-color: #333;
}

.autorization_box {
  display: block;
  background-color: darkgray;
  color: black;
}

.registration_button {
  background-color: darkseagreen;
  color: black;
}

.registration_button:hover {
  cursor: pointer;
}

.goback_button {
  background-color: aqua;
  color: black;
}

.goback_button:hover {
  cursor: pointer;
}

.content {
  width: 100%;
  min-height: 1px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: aqua;
}

.home_page {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  min-height: 1px;
  background-color: white; /* gray;*/
}

.about_page {
  width: 100%;
  display: block;
  min-height: 1px;
  background-color: #CCE0D4;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.about_page a {
  color: #E47E1A;
  font-size: 16px;
}

.about_page_img {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about_page_img img {
  width: 150px;
  height: 150px;
}

.contact_container {
  display: block;
  width: 100%;
  min-height: 1px;
  padding: 40px;
}
.contact_item {
  display: flex;
  width: 100%;
  margin: 30px;
}

.contact_item_text {
  color: black;
  font-size: 16px;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 30px;
  cursor: pointer;
}

.contact_item_text a {
  color: #E47E1A;
}

.contact_item_image {
  width: 39px;
  height: 40px;
}

.filter_block {
  width: 100%;
  min-height: 1px;
  background-color: yellow;
  text-align: center;
}

.filter_button {
  margin-left: 10px;
  margin-right: 10px;
}

.filter_button:hover {
  cursor: pointer;
}

.sort_button {
  margin-left: 10px;
  margin-right: 10px;
}

.sort_button:hover {
  cursor: pointer;
}

.card_item {
  display: grid;
  grid-template-rows: 320px auto 38px;
  width: 320px;
  height: 460px;
  gap: 5px;
  margin: 5px;
  overflow: hidden;
  cursor: pointer;
}

.card_item_image {
  width: 320px;
  height: 320px;
  border-radius: 30px;
  overflow: hidden;
  object-fit: cover;
  background-color: #000000;
}

.card_item_text {
  display: block;
  width: 100%;
  min-height: 1px;
  color: #373737;
  background-color: #CCE0D4;
  font-size: 15px;
  font-weight: 400;
  padding-left: 15px;
  padding-right: 15px;
}

.card_item_text p {
  margin-top: 4px;
  margin-bottom: 4px;
  color: #373737;
}

.profile {
  display: flex;
  flex-direction: column; /* Расположение сверху вниз */
  /*justify-content: center; /* Центрирование по вертикали */
  align-items: center; /* Центрирование по горизонтали */
  width: 100%;
  height: 100%;
  text-align: center;
}

.nickname {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 1px;
  margin-top: 10px;
}

.nickname_label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  font-size: 15px;
  font-weight: 400;
  color: #000000;
  /*padding-top: 10px;*/
}

/*.name_nursery_input {
  display: flex;
  flex-wrap: nowrap;
  width: fit-content;
  height: 40px;
  border-radius: 30px;
  margin-left: 5px;
  gap: 3px;
}*/

.nickname_input_box {
  display: flex;
  flex-wrap: nowrap;
  width: fit-content;
  height: 40px;
  border-radius: 30px;
  margin-left: 5px;
  gap: 3px;
}

.name_nursery_input_fld {
  /*margin-left: 5px;*/
  width: 300px;
  height: 50px;
  font-size: xx-large;
  font-weight: bolder;
  border-radius: 30px;
  color: black;
  border: none;
  outline: none;
  padding-left: 15px;
  transition: border-color 0.2s ease; /* Плавный переход цвета */
}

.name_nursery_input_fld:focus {
  border: 1px solid #1E7040; /* Темно-зеленый цвет рамки */
}

.cat_description_input_fld {
  /*margin-left: 5px;*/
  width: 195px; /* Фиксированная ширина */
  height: 160px; /* Фиксированная высота */
  resize: none; /* Запрещаем изменение размеров */
  overflow-y: auto; /* Добавляем прокрутку при необходимости */
  border-radius: 30px;
  color: black;
  border: none;
  outline: none;
  padding-left: 15px;
  padding-right: 15px;
  transition: border-color 0.2s ease;
}

.description_input_fld {
  /*margin-left: 5px;*/
  width: 300px; /* Фиксированная ширина */
  height: 160px; /* Фиксированная высота */
  resize: none; /* Запрещаем изменение размеров */
  overflow-y: auto; /* Добавляем прокрутку при необходимости */
  border-radius: 30px;
  color: black;
  border: none;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  transition: border-color 0.2s ease; /* Плавный переход цвета */
}

.description_input_fld:focus {
  border: 1px solid #1E7040; /* Темно-зеленый цвет рамки */
}

.cat_input_fld {
  /*margin-left: 5px;*/
  width: 200px;
  height: 40px;
  border-radius: 30px;
  color: black;
  border: none;
  outline: none;
  padding-left: 15px;
  transition: border-color 0.2s ease; /* Плавный переход цвета */
}

.selection_input_fld {
  /*margin-left: 5px;*/
  width: 220px;
  height: 40px;
  border-radius: 30px;
  color: black;
  border: none;
  outline: none;
  padding-left: 15px;
  transition: border-color 0.2s ease; /* Плавный переход цвета */
}

.nickname_input_fld {
  /*margin-left: 5px;*/
  width: 240px;
  height: 40px;
  border-radius: 30px;
  color: black;
  border: none;
  outline: none;
  padding-left: 15px;
  transition: border-color 0.2s ease; /* Плавный переход цвета */
}

.nickname_input_fld_invalid {
  /*margin-left: 5px;*/
  width: 240px;
  height: 40px;
  border-radius: 30px;
  color: red;
  border: none;
  outline: none;
  padding-left: 15px;
  transition: border-color 0.2s ease; /* Плавный переход цвета */
}

.nickname_input_fld:focus {
  border: 1px solid #1E7040; /* Темно-зеленый цвет рамки */
}

.escd_button_block {
  display: flex;
  gap: 3px;
  margin-top: 3px;
}

.escd_button {
  background-color: inherit;
  width: 40px;
  height: 40px;
  border-radius: 25%;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; 
  transition: background-color 0.5s ease;
}

.escd_button:hover {
  background-color: #e0e0e0;
}

.escd_button img {
  width: 25px; /* Подгоняем изображение под размеры кнопки */
  height: 25px;
  /*object-fit: fill;*/
}

.escd_button:disabled {
  opacity: 0.2;                /* Делает кнопку блеклой */
  cursor: not-allowed;
}

.escd_button_delete {
  /*background-color: #E47E1A;*/
  background-color: #CCE0D4;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Обрезаем лишние части изображения */
  /*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Небольшая тень */
  transition: background-color 0.2s ease;
}

.escd_button_delete:hover {
  /*background-color: #e0e0e0;*/ /* Изменение цвета при наведении */
  border:1px solid #000000;
}

.escd_button_delete img {
  width: 25px; /* Подгоняем изображение под размеры кнопки */
  height: 25px;
  /*object-fit: fill;*/
}

.escd_button_delete:disabled {
  opacity: 0.2;                /* Делает кнопку блеклой */
  cursor: not-allowed;
}

.full_card_modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #CCE0D4;
  /*background-color: rgba(0, 0, 0, 0.4);*/
  /*backdrop-filter: blur(10px);*/
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4500;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
}

.full_card_modal_content_common {
  position: relative;
  width:700px;
  height: 550px;
  max-height: 550px;
  display: grid;
  grid-template-rows: 500px 50px;
  gap: 2px;
  overflow: hidden;
}

.full_card_content_common_info {
  height: 500px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
  background-color: #1E7040;
  border-radius: 30px;
  color: white;
  gap: 10px;
}

.full_card_column_button {
  width: 70px;
  height: 330px;
  display: grid;
  grid-template-rows: 60px auto 60px;
  align-items: center;
  justify-content: center;
}

.full_card_button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.full_card_step_button {
  width: 40px;
  height: 40px;
  border-radius: 25%;
  color: #1E7040;
  /*background-color: white;*/
  background-color: inherit;
  /*border: 1px solid #1E7040;*/
  border: none;
  display: flex;
  justify-content: center; /* Центрирует по горизонтали */
  align-items: center; 
  cursor: pointer;
  transition: background-color 0.5s ease;
}

.full_card_step_button:hover {
  background-color: #e0e0e0;
}

.full_card_step_button.disabled {
  opacity: 0.5; /* Делает кнопку блеклой */
  cursor: not-allowed; /* Указывает, что кнопка неактивна */
  /*background-color: #CCE0D4;*/
}

.full_card_step_button img {
  width: 20px; /* Подгоняем изображение под размеры кнопки */
  height: 20px;
  /*object-fit: fill;*/
}

.full_card_close_button {
  position: absolute;
  top: 35px;
  right: 35px;
  width: 40px;
  height: 40px;
  border-radius: 25%;
  /*align-self: flex-start;*/
  /*justify-self: center;*/
  /*border: 1px solid #FFFFFF;*/
  /*background-color: #37373780;*/
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
  cursor: pointer;
  box-shadow: none;
}

.modal_close_button_5000 {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  border-radius: 25%;
  border: none;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: none;
  z-index: 5000;
}

.modal_close_button_5000 img {
  height: 20px;
  width: 20px;
}


.modal_close_button_5000:hover {
  background-color: #e0e0e0;
}

.modal_close_button {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  border-radius: 25%;
  border: none;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: none;
  z-index: 4000;
}

.modal_close_button img {
  height: 20px;
  width: 20px;
}


.modal_close_button:hover {
  background-color: #e0e0e0;
}


.full_card_close_button:hover {
  background-color: #e0e0e0;
  color: inherit;
}

.full_card_close_button_nursery {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-self: flex-start;
  justify-self: center;
  border: 1px solid #FFFFFF;
  background-color: #37373780;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
  cursor: pointer;
}

.full_card_close_button img {
  width: 100%;                      /* Масштабируем изображение на всю ширину контейнера */
  height: 100%;                     /* Масштабируем изображение на всю высоту контейнера */
  object-fit: cover;                /* Пропорционально заполняет круг */
}

.full_card_close_button_nursery img {
  width: 100%;                      /* Масштабируем изображение на всю ширину контейнера */
  height: 100%;                     /* Масштабируем изображение на всю высоту контейнера */
  object-fit: cover;                /* Пропорционально заполняет круг */
}

.full_card {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 1px;
  max-height: 430px;
  background-color: #1E7040;
  border-radius: 30px;
  overflow: hidden;
}

.full_card_image {
  display: block;
  width: 300px;
  height: 100%;
  position: relative;
  min-height: 1px;
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
}

.full_card_text {
  display: block;
  width: 300px;
  height: 95%;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  min-height: 1px;
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 20px;
  color: white;
  font-size: 14px;
  font-weight: 400;
}

.full_card_image img {
  width: 350px;
  height: 350px;
  border-radius: 30px;
  object-fit: cover;
  margin-bottom: 5px;
}

.full_card_content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;
  background-color: #1E7040;
  border-radius: 30px;
  gap: 5px;
}

.new_full_card {
  display: flex;
  flex-wrap: wrap;
  width: 700px;
  min-height: 1px;
  max-height: 430px;
  background-color: #1E7040;
  border-radius: 30px;
  overflow: hidden;
  gap: 5px;
}

.new_full_card_image {
  display: block;
  width: 330px;
  min-height: 1px;
  max-height: 460px;
  margin-left: 10px;
  margin-top: 20px;
  margin-right: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.new_full_card_text {
  display: block;
  width: 330px;
  min-height: 1px;
  max-height: 420px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 20px;
  color: white;
  font-size: 14px;
  font-weight: 400;
}

.copy_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border: none;
  padding: 10px;
  border-radius: 8px;
  background-color: inherit;
}

.copy_input {
  border: none;
  padding: 5px;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  background-color: inherit;
  color: inherit;
  outline: none;
  font-size: 14px;
  font-weight: 400;
}

.copy_button {
  background-color: inherit;
  color: inherit;
  border: none;
  padding: 6px 12px;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.5s;
}

.copy_button:hover {
  background-color: #e0e0e0;
  color: inherit;
}

.copy_pictogram {
  width: 100%;
  display: grid;
  gap: 5px;
  grid-template-columns: 15px auto;
}

.copy_pictogram img {
  width: 15px;
  height: 15px;
}

.navigation_button_block {
  width: 100%;
  height: 49px;
  display: grid;
  grid-template-columns: 50% 50%;
}

.full_card_button_bar {
  width: fit-content;
  height: 17px;
  background-color: #1E7040;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center; /* Центрирует по горизонтали */
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.full_card_button_bar button {
  cursor: pointer;
}

.photo_slider_button {
  width: 17px;
  height: 17px;
  color: #E47E1A;
  background-color: #1E7040;
  border: none;
  margin-left: 3px;
  margin-right: 3px;
}

.photo_slider_item {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  border: none;
  border: 1px solid #FFFFFF;
  background-color: white;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.photo_slider_item_active {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  border: none;
  border: 1px solid #FFFFFF;
  background-color: #E47E1A;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.photo_card {
  margin-bottom: 50px;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.styled-table_info {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.row {
  border-bottom: 1px dashed white;
}

.cell1 {
  width: 80px;
  padding: 8px;
}

.cell2 {
  width: 150px;
  padding: 8px;
}

.cell2_photo {
  width: 200px;
  padding: 8px;
}

.full_card_telephone_number {
  color: #E47E1A;
  font-size: 21px;
  font-weight: 400;
  cursor: pointer;
}

.short_card_telephone_number {
  color: #E47E1A;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.nursery {
  width: 320px;
  height: 220px;
  color: white;
  background-color: #1E7040;
  border-radius: 30px;
  border: #1E7040 solid 1px;
  font-size: 14px;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 15px;
  cursor: pointer;
}

.element {
  width: fit-content;
  height: fit-content;
  position: relative;
  text-align: center;
}

.delete_button_element {
  position: absolute;
  top: 15px;
  right: 10px;
}

.nursery_info {
  width: 320px;
  min-height: 140px;
  color: black;
  background-color: #CCE0D4;
  font-size: 14px;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 15px;
}

.nursery_window_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #CCE0D4;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 400;
  overflow-x: auto;
}

.nursery_window_content_common {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 87%;
  min-height: 1px;
  max-height: 90vh;
  background-color: #CCE0D4;
  overflow-y: auto;
  overflow-x: auto;
  padding: 20px;
}

.nursery_window_content_common h2 {
  width: 100%;
  min-height: 1px;
  background-color: #CCE0D4;
  color: black;
  font-size: 24px;
  font-weight: 400;
  padding-left: 15px;
  padding-right: 15px;
  text-transform: uppercase;
}

.nursery_pet_short_card {
  width: 320px;
  margin: 5px;
  overflow-wrap: break-word;
  overflow: hidden;
  /*cursor: pointer;*/
}

.nursery_pet_short_card p {
  margin-top: 3px;
  margin-bottom: 3px;
  font-size: 12px;
  font-weight: 400;
}

.nursery_pet_short_card h3 {
  font-size: 12px;
  font-weight: 800;
  
}

.nursery_pet_short_card h4 {
  font-size: 14px;
  font-weight: 400;
}

.nursery_achievements {
  width: 300px;
  height: 500px;
  display: block;
  overflow: hidden;
  border: 1px solid #000000;
}

.nursery_achievements img {
  object-fit: contain;
  width: 300px;
  height: 300px;
  border: 1px solid #000000;
}

.nursery_box {
  display: block;
}

.nursery_section {
  width: 300px;
  min-height: 1px;
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
}

.nursery_section_head {
  width: 270px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nursery_section_head_active {
  width: 270px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nursery_section_head_active h2 {
  color: #E47E1A;
}

.nursery_section_button {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nursery_section_button_active {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nursery_section_button_active h2 {
  color: #E47E1A;
}

.nursery_section_card {
  display: flex;
  flex-wrap: wrap;
}

.button_more {
  width: 320px;
  height: 35px;
  padding: 10px 20px 10px 20px;
  border-radius: 20px 20px 20px 20px;
  border: 1px solid #1E7040;
  background-color: #CCE0D4;
  color: #373737;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.button_adv_more {
  width: 320px;
  height: 35px;
  padding: 10px 20px 10px 20px;
  border-radius: 20px 20px 20px 20px;
  border: 1px solid #E47E1A;
  background-color: #E47E1A;
  color: white;
  font-size: 14px;
  font-weight: 400;
}

.advertiser_item {
  width: 340px;
  height: 120px;
  margin: 5px;
  background-color: crimson;
}

.advertiser_item:hover {
  cursor: pointer;
}

.my_nursery_short {
    display: grid;
    grid-template-columns: 60px auto;
    width: 340px;
    height: 60px;
    margin: 5px;
    background-color: magenta;
}

.nursery_edit {
  display: block;
  background-color: darkslategray;
  color: antiquewhite;
  width: 340px;
  min-height: 1px;
}

.my_nursery_short:hover {
  cursor: pointer;
}

.my_nursery_short_img {
  font-size: x-small;
}

.my_nursery_short_text {
  display: block;
  background-color: bisque;
  color: black;
  min-height: 1px;
}

.go_back {
  width: 60px;
  min-height: 1px;
  color: blue;
  background-color: chartreuse;
}

.go_back:hover {
  cursor: pointer;
}

.full_card_item {
  display: flex;
  width: 100%;
  min-height: 1px;
  margin: 5px;
  background-color: #61dafb;
}

.full_card_item_image {
  background-color: brown;
  width: 340px;
  height: 420px;
  display: block;
}

.image_bar {
  width: 340px;
  height: 80px;
  background-color: chocolate;
}

.image_bar_item {
  display: inline;
  margin: 3px;
  width: 74px;
  height: 74px;
  background-color: bisque;
  border-width: 0px;
}

.image_bar_item_active {
  display: inline;
  margin: 3px;
  width: 64px;
  height: 64px;
  background-color: black;
  border-width: 3px;
  border-radius: 5px;
  border-color: darkturquoise;
  overflow: hidden;
}

.image_bar_item:hover {
  cursor: pointer;
}

.append_photo {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item_bar_photo {
  object-fit: cover;
  overflow: hidden;
  width: 74px;
  height: 74px;
}

.full_photo_cat {
  object-fit: cover;
  overflow: hidden;
  width: 330px;
  height: 330px;
  border-radius: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.place_photo_cat {
  object-fit: cover;
  overflow: hidden;
  width: 300px;
  height: 300px;
  border-radius: 30px;
  border: thick dashed white;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.place_photo_cat button {
  width: 120px;
  height: 30px;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  overflow: hidden;
  box-shadow: none;
  margin-bottom: 5px;
  margin-top: 5px;
}

.place_photo_cat p {
  text-align: center;
}

.full_photo {
  object-fit: cover;
  overflow: hidden;
  width: 340px;
  height: 340px;
}

.full_card_item_text {
  display: block;
  background-color:  chartreuse;
  width: 340px;
  min-height: 1px;
}

.favorite_button {
  background-color: blueviolet;
  color: white;
}

.favorite_button:hover {
  cursor: pointer;
}

.write_message_button {
  background-color: coral;
  color: black;
}

.write_message_button:hover {
  cursor: pointer;
}


.tel_number_hiden {
  color: red;
}

.tel_number_hiden:hover {
  cursor: pointer;
}

.nursery_page {
  width: 100%;
  min-height: 1px;
  background-color:chartreuse ;
}

.petfood_page {
  width: 100%;
  min-height: 1px;
  background-color: blueviolet;
}

.animal_care_page {
  width: 100%;
  min-height: 1px;
  background-color: brown;
  color: white;
}

.grooming_page {
  width: 100%;
  min-height: 1px;
  background-color: chocolate;
  color: white;
}

.expo_page {
  width: 100%;
  min-height: 1px;
  color: white;
  background-color:darkblue;
}

.clab_page {
  width: 100%;
  min-height: 1px;
  background-color: darkgoldenrod;
}

.shelter_page {
  width: 100%;
  min-height: 1px;
  background-color: darkgray;
}

.hotel_page {
  width: 100%;
  min-height: 1px;
  color: white;
  background-color: darkgreen;
}

.vet_page {
  width: 100%;
  min-height: 1px;
  background-color: darkcyan;
}

.carrier_page {
  width: 100%;
  min-height: 1px;
  background-color: darkkhaki;
}

.ticket_item {
  width: 100%;
  min-height: 1px;
  color: white;
  background-color: darkmagenta;
  margin: 3px;
}

.dialog {
  width: 340px;
  min-height: 1px;
  background-color: aquamarine;
  color: black;
  margin-top: 3px;
  margin-bottom: 3px;
}

.message_text {
  width: 340px;
  min-height: 30px;
}

.transmit_message_box {
  width: 340px;
  min-height: 1px;
  background-color: darkgreen;
  color: white;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 30px;
}

.recive_message_box {
  width: 340px;
  min-height: 1px;
  background-color: navajowhite;
  color: black;
  margin-top: 3px;
  margin-bottom: 3px;
}

.ticker_card_text {
  width: 340px;
  min-height: 1px;
  background-color: darkgreen;
  color: white;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 30px;
}

.ticker_header {
  min-height: 1px;
  background-color: blue;
  color: yellow;
}

.write_message {
  background-color: darkgray;
  min-height: 1px;
  margin-top: 5px;
}

.vacancy_page {
  width: 100%;
  min-height: 1px;
  background-color: darkorange;
}

.contact_page {
  width: 100%;
  min-height: 1px;
  background-color: deeppink;
}

.send_mail {
  text-decoration: none;
  color: white;
}

.copyright_box {
  width: 100%;
  min-height: 1px;
  border-top: 1px solid  white;
}

.copyright {
  margin-bottom: 10px;
}

.creator_href {
  color: white;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
}

.nursery_menu {
  background-color: aquamarine;
  min-height: 1px;
  display: flex;
}

.nursery_menu_item {
  background-color: magenta;
  color: black;
  min-height: 1px;
  width: 150px;
  margin: 5px;
}

.nursery_menu_item:hover {
  cursor: pointer;
}

@media (max-width: 375px) {
  .login_button {
    width: 40px;
  }
  
  .login_button_open {
    width: 40px;
  }

  .status_bar {
    grid-template-columns: auto 45px;
  }

  .full_card_modal_content_common {
    width: 370px;
  }

  .main_menu {
    padding-left: 0px;
    display: none;
  }

  .burger_main_menu {
    display: block;
  }

  .full_card {
    overflow-y: auto;
    overflow-x: auto;
  }
}

@media (min-width: 376px) and (max-width: 669px) {
  .full_card_modal_content_common {
    width: 370px;
  }

  .main_menu {
    padding-left: 0px;
    display: none;
  }

  .burger_main_menu {
    display: block;
  }

  .full_card {
    overflow-y: auto;
    overflow-x: auto;
  }

}

@media (min-width: 670px) and (max-width: 959px) {
  .full_card_modal_content_common {
    width: 370px;
  }
  
  .full_card {
    overflow-y: auto;
    overflow-x: auto;
  }
}

@media (min-width: 960px) and (max-width: 1019px) {
  .full_card {
    overflow-y: auto;
    overflow-x: auto;
  }
}

@media (min-width: 1020px) and (max-width: 1279px) {

}

@media (min-width: 1280px) and (max-width: 1439px) {

}

@media (min-width: 1440px) and (max-width: 1919px) {

}

@media (min-width: 1920px) {

}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.debug_entry_button {
  background-color: #282c34;
  color: white;
  font-size: 16px;
  font-weight: 700;
  border-width: 0px;
}

.debug_login_box {
  display: block;
}

.debug_login_field_box {
  margin: 5px;
  
}

.debug_login_field {
  font-size: 16px;
}

.condition_met {
  color: green;
}

.condition_not_met {
  color: red;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
